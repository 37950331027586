'use client';
import { useAlert } from '@/contexts/AlertProvider';
import { getImage } from '@/services/ecommerce/utils/helper';
import generateBlockLink from '@/services/ecommerce/utils/LinkGenerator';
import usePixalEvent from '@/services/event/usePixalEvent';
import { Block } from '@/services/types/blocksTypes';
import { map } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React from 'react';
import Slider from '../molecules/Slider';

interface Props {
	data: Block;
	sl?: any;
}
const CarouselBlock: React.FC<Props> = ({ data }) => {
	const router = useRouter();
	const alert = useAlert();
	const { block_data, block_display_type } = data;
	const { children } = generateBlockLink(data);
	const { EventBannerAction } = usePixalEvent();
	const openInNewTab = (url) => {
		typeof window !== 'undefined' && window.open(url, '_blank', 'noopener,noreferrer');
	};
	const handleImageClick = (data: any) => {
		if (data?.isValid) {
			if (data?.target === '_blank') {
				openInNewTab(data.link);
				return;
			}
			router.push(data.link);
		} else {
			alert.error({
				message: 'No link found',
				provider: 'toast'
			});
		}
	};
	const isLinkAvailable = (data: any) => {
		if (data?.isValid) {
			return true;
		} else {
			return false;
		}
	};

	if (block_display_type === 'web_top_carousel') {
		return (
			<div className='container mx-auto my-30'>
				<Slider autoplay={true} pagination={true} style={{ borderRadius: 12 }}>
					{block_data?.map((d: any, i: number) => {
						return (
							<div
								key={i}
								onClick={() => {
									const linkdata = children[i];
									EventBannerAction({
										id: d.uuid,
										name: d?.title
									});
									handleImageClick(linkdata);
								}}
								style={{
									position: 'relative',
									aspectRatio: 1348 / 480,
									width: '100%',
									borderRadius: 12,
									cursor: isLinkAvailable(children[i]) ? 'pointer' : 'default'
								}}>
								<Image
									unoptimized={true}
									priority
									src={getImage(d.file)}
									height={480}
									width={1348}
									alt='arogga slider image'
									style={{
										borderRadius: 12,
										width: '100%',
										aspectRatio: 1348 / 480,
										height: 'auto'
									}}
								/>
							</div>
						);
					})}
				</Slider>
			</div>
		);
	}

	if (block_display_type === 'web_beauty_top_carousel') {
		return (
			<div className='container mx-auto my-30'>
				<Slider pagination={true} style={{ borderRadius: 12 }}>
					{block_data?.map((d: any, i: number) => {
						return (
							<div
								key={i}
								onClick={() => {
									const linkdata = children[i];
									EventBannerAction({
										id: d.uuid,
										name: d?.title
									});
									handleImageClick(linkdata);
								}}
								style={{
									position: 'relative',
									aspectRatio: 1348 / 480,
									width: '100%',
									borderRadius: 12,
									cursor: isLinkAvailable(children[i]) ? 'pointer' : 'default'
								}}>
								<Image
									unoptimized={true}
									priority
									src={getImage(d.file)}
									layout='fill'
									alt='arogga slider image'
									style={{
										borderRadius: 12,
										width: '100%',
										aspectRatio: 1348 / 480
									}}
								/>
							</div>
						);
					})}
				</Slider>
			</div>
		);
	}
	if (block_display_type === 'horizontal') {
		return (
			<div className='container mx-auto my-30'>
				<Slider
					pagination={true}
					style={{
						borderRadius: 12
					}}>
					{block_data?.map((d: any, i: number) => {
						return (
							<div
								key={i}
								onClick={() => {
									const linkdata = children[i];
									EventBannerAction({
										id: d.uuid,
										name: d?.title
									});
									handleImageClick(linkdata);
								}}
								style={{
									position: 'relative',
									aspectRatio: 1348 / 480,
									width: '100%',
									borderRadius: 12,
									cursor: isLinkAvailable(children[i]) ? 'pointer' : 'default'
								}}>
								<Image
									unoptimized={true}
									priority
									src={getImage(d.file)}
									layout='fill'
									alt='arogga slider image'
									style={{
										borderRadius: 12,
										width: '100%',
										aspectRatio: 1348 / 480
									}}
								/>
							</div>
						);
					})}
				</Slider>
			</div>
		);
	}
	if (block_display_type === 'blog_top_carousel') {
		return (
			<div className='container mx-auto my-30'>
				<Slider pagination={true} style={{ borderRadius: 12 }}>
					{map(block_data, (d: any, i) => {
						return (
							<div
								onClick={() => {
									const linkdata = children[i];
									EventBannerAction({
										id: d.uuid,
										name: d?.title
									});
									handleImageClick(linkdata);
								}}
								key={i}
								className='d-flex flex-column justify-space-between items-center'
								style={{
									width: '100%',
									height: '480px',
									borderRadius: '10px',
									backgroundImage: `url(${getImage(d.file)})`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									display: 'flex',
									cursor: isLinkAvailable(children[i]) ? 'pointer' : 'default'
								}}>
								<div
									style={{
										width: '100%',
										height: '100%',
										// backgroundColor: "rgba(2, 2, 2, 0.52)",
										borderRadius: '10px',
										paddingLeft: '20px'
									}}>
									<div className='text-18 text-white fw-400 mt-20'>{d.title ?? ''}</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		);
	}
	if (block_display_type === 'job_top_carousel') {
		return (
			<div className='container mx-auto my-30'>
				<Slider pagination={true} style={{ borderRadius: 12 }}>
					{map(block_data, (d: any, i) => {
						return (
							<div
								key={i}
								onClick={() => {
									const linkdata = children[i];
									EventBannerAction({
										id: d.uuid,
										name: d?.title
									});
									handleImageClick(linkdata);
								}}
								className='d-flex flex-column justify-space-between items-center'
								style={{
									width: '100%',
									height: '480px',
									borderRadius: '10px',
									backgroundImage: `url(${getImage(d.file)})`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									display: 'flex',
									cursor: isLinkAvailable(children[i]) ? 'pointer' : 'default'
								}}>
								<div
									style={{
										width: '100%',
										height: '100%',
										backgroundColor: 'rgba(0,0,0,0.52)',
										borderRadius: '10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column'
									}}>
									<div className='text-48 text-white fw-700'>{d.title ?? ''}</div>
									<div className='text-18 text-white fw-400 mt-20'>{d.subtitle ?? ''}</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		);
	}
};

export default CarouselBlock;
