'use client';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import { API_PUBLIC } from '@/services/ecommerce/apis/apiConstrant';
import { shimmer, toBase64 } from '@/services/ecommerce/utils/LoadingImage';
import { get, omit } from 'lodash';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import 'swiper/css/pagination';
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination, Virtual } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

interface SliderWarpProps {
	children: React.ReactNode;
	itemClassName?: string;
	slidesPerView?: number;
	navigation?: boolean;
	pagination?: boolean;
	spaceBetween?: number;
	autoplay?: boolean;
	className?: string;
	ref?: any;
	onSlideChange?: any;
	responsive?: boolean | any;
	style?: any;
	loop?: boolean;
	shouldFetchData?: boolean | any;
	SlideComponent?: any;
	params?: any;
	slidesPerGroup?: number;
	shouldSlidesPerGroup?: any;
}
// eslint-disable-next-line react-hooks/rules-of-hooks
const SliderModules = memo(
	({
		children,
		itemClassName,
		slidesPerView,
		navigation,
		pagination,
		spaceBetween,
		autoplay,
		className,
		responsive = false,
		ref = useRef<SwiperRef>(null), // eslint-disable-line
		style,
		onSlideChange,
		loop,
		shouldFetchData,
		SlideComponent,
		params,
		slidesPerGroup,
		shouldSlidesPerGroup
	}: SliderWarpProps) => {
		const [data, setData] = useState([]);
		const [currentPage, setCurrentPage] = useState(1);
		const [isLoading, setIsLoading] = useState(false); // Loading state
		const [hasMore, setHasMore] = useState(true); // New state for tracking if more data is available
		const [currentSlidesPerView, setCurrentSlidesPerView] = useState(slidesPerView || 1);

		const loadData = async (currentPage) => {
			setIsLoading(true); // Start loading
			const childArray = React.Children.toArray(children || []); // Ensure children is not null or undefined
			const childCount = childArray.length;

			// Validate slidesPerView and ensure it's a number
			if (shouldFetchData && typeof slidesPerView === 'number' && slidesPerView > childCount) {
				setHasMore(false);
				setIsLoading(false); // End loading
				return;
			}

			try {
				const finalParams = omit(
					{
						...params,
						_page: currentPage,
						_block: shouldFetchData,
						_allow_sales: 1
					},
					['source']
				) as any;
				const { data, status } = await AroggaAPI.get(API_PUBLIC.GET_BLOCK, finalParams);
				if (status === 'fail') {
					setHasMore(false);
					setIsLoading(false); // End loading
					return;
				}
				let blockdata = get(data, '[0][block_data]', []);

				if (blockdata.hasOwnProperty('data')) {
					if (!blockdata.total) {
						setHasMore(false); // Set hasMore to false if no more data
						setIsLoading(false); // End loading
						return;
					}
					blockdata = blockdata.data;
				}

				if (blockdata.length === 0) {
					setHasMore(false); // Set hasMore to false if no more data
					return;
				}

				setData((prev) => [...prev, ...blockdata]);
			} catch (error) {
				console.error('Error loading data:', error);
				setHasMore(false);
				setIsLoading(false); // End loading
			} finally {
				setIsLoading(false); // End loading
			}
		};
		const handleReachEnd = () => {
			if (hasMore) {
				// Only load more data if hasMore is true
				setCurrentPage((prevPage) => prevPage + 1);
			}
		};

		useEffect(() => {
			currentPage !== 1 && hasMore && loadData(currentPage);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentPage, hasMore]);

		const renderSlides = () => {
			const childArray = React.Children.toArray(children);
			const slides = [];
			childArray.forEach((child, index) => {
				slides.push(
					<SwiperSlide key={`child-slide-${index}`} className={itemClassName}>
						{child}
					</SwiperSlide>
				);
			});

			// Append slides generated from API data by cloning the same child component
			data.forEach((apiDataItem, index) => {
				slides.push(
					<SwiperSlide key={`api-slide-${index}`} className={itemClassName}>
						<SlideComponent data={apiDataItem} />
					</SwiperSlide>
				);
			});

			// Append loading slide if isLoading is true
			if (isLoading) {
				slides.push(
					<SwiperSlide key='loading-slide' className={itemClassName}>
						<LoadingComponent slidesPerView={1} />
					</SwiperSlide>
				);
			}

			return slides;
		};

		const handleBreakpoint = (swiper) => {
			setCurrentSlidesPerView(swiper.params.slidesPerView);
			const current = swiper.activeIndex + swiper.params.slidesPerView;
			const totalData = React.Children.toArray(children)?.length + data?.length;
			const remaining = totalData - current;
			if (remaining < swiper.params.slidesPerView && shouldFetchData) {
				handleReachEnd();
			}
		};

		const onSlideChanges = (swiper) => {
			// setCurrentSlidesPerView(swiper.params.slidesPerView);
			onSlideChange && onSlideChange(swiper);
			handleBreakpoint(swiper);
		};

		return (
			<Swiper
				style={{
					overflowY: 'visible',
					position: 'relative',
					...style
				}}
				ref={ref}
				onSlideChange={onSlideChanges}
				className={className}
				slidesPerGroup={shouldSlidesPerGroup ? currentSlidesPerView : 1}
				navigation={navigation || true}
				slidesPerView={slidesPerView || 1}
				spaceBetween={spaceBetween || 10}
				autoplay={autoplay || false}
				pagination={pagination ? { clickable: true } : false}
				resizeObserver={true}
				loop={loop}
				{...(shouldFetchData && {
					onReachEnd: handleReachEnd
				})}
				modules={[Navigation, Pagination, Autoplay, Keyboard, Mousewheel, Virtual]}
				breakpoints={
					(responsive && {
						...responsive
					}) ||
					{}
				}
				virtual
				onBreakpoint={handleBreakpoint}>
				{renderSlides()}
			</Swiper>
		);
	}
);
SliderModules.displayName = 'SliderModules';

const LoadingComponent = memo(({ slidesPerView }: any) => {
	const validSlides = parseInt(slidesPerView) > 0 ? parseInt(slidesPerView) : 1;
	return (
		<div className='d-flex gap-20'>
			{Array.from(Array(validSlides).keys()).map((i) => {
				return (
					<Image
						key={i}
						unoptimized={true}
						placeholder='blur'
						blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(220, 186))}`}
						src={`data:image/svg+xml;base64,${toBase64(shimmer(200, 186))}`}
						width={validSlides === 1 ? 220 : 186}
						height={350}
						style={{
							width: 100 / validSlides + '%',
							borderRadius: validSlides === 1 ? 12 : 10,
							height: 'auto'
						}}
						alt={'Loading'}
					/>
				);
			})}
		</div>
	);
});
LoadingComponent.displayName = 'LoadingComponent';
const LoadingComponentWrapper = (props) => {
	return <LoadingComponent {...props} />;
};
const Slider = (props) => {
	const DynamicSliderModules = useMemo(
		() =>
			dynamic(() => Promise.resolve(SliderModules), {
				loading: () => <LoadingComponentWrapper {...props} />,
				ssr: false
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <DynamicSliderModules {...props} />;
};
export default Slider;
