'use client';

import { useAlert } from '@/contexts/AlertProvider';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import useDeepCompareMemo from '@/services/ecommerce/hooks/useDeepCompareMemo';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IInfiniteScrollComponent {
	endpoint: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	initialFilter?: any;
	children: (item: any, index: number, mutate: any, reset: any, clear: any) => any;
	CusomLoader: () => JSX.Element;
	EmptyComponent: JSX.Element;
	className?: string;
	PAGE_SIZE?: number;
	uniqueKey?: number | string;
	handleMutateCallback?: (mutate: any) => void;
	style?: any;
	dataKey?: string;
	onLoad?: (res: any) => void;
	scrollableTarget?: any;
	onFinish?: (res: any) => void;
	onLoading?: (status: boolean) => void;
	firstParams?: any;
}

const InfiniteScrollComponent = ({
	endpoint,
	method,
	initialFilter,
	children,
	CusomLoader,
	EmptyComponent,
	className,
	PAGE_SIZE = 5,
	uniqueKey,
	handleMutateCallback,
	style,
	dataKey,
	onLoad,
	scrollableTarget,
	onFinish,
	onLoading,
	firstParams
}: IInfiniteScrollComponent) => {
	const memoizedFilter = useDeepCompareMemo(initialFilter) as any;
	const [page, setPage] = useState(1);
	const [items, setItems] = useState([]);
	const [isFetchingPage, setIsFetchingPage] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const alert = useAlert();
	// Function to fetch data using AroggaAPI
	const fetchData = async (p) => {
		if (isFetchingPage) return;
		setIsFetchingPage(true);
		page == 1 && onLoading && onLoading(true);
		try {
			const response: any = await AroggaAPI.get(endpoint, {
				_page: p || page,
				_perPage: PAGE_SIZE,
				...memoizedFilter
			});

			if (response?.message === 'Maintenance, Please try again later') {
				alert.error({
					message: 'Maintenance, Please try again later',
					title: 'Error'
				});
			}
			if (response.status === 'fail') {
				setHasMore(false);
				onFinish && onFinish(response);
				onLoad &&
					onLoad({
						total: 0,
						suggestion: response?.suggestion || [],
						filters: response?.filters || {}
					});
				onLoading && onLoading(false);
				return;
			}

			if (response.data) {
				const dataSource = dataKey ? get(response.data, dataKey) : response.data;
				onLoad && onLoad(response);
				if (dataSource && dataSource.length) {
					setItems((prev) => {
						const idPath = uniqueKey ? uniqueKey : 'id';
						const itemsMap = new Map(prev?.map((item) => [get(item, idPath), item]));

						dataSource.forEach((item) => {
							const itemId = get(item, idPath);
							if (!itemsMap.has(itemId)) {
								itemsMap.set(itemId, item);
							}
						});

						// Convert the map back into an array
						return Array.from(itemsMap.values());
					});
					setHasMore(dataSource.length === PAGE_SIZE);
				} else {
					setHasMore(false);
				}
				onFinish && onFinish(response);
				onLoading && onLoading(false);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			onLoading && onLoading(false);
		} finally {
			setIsFetchingPage(false);
			onLoading && onLoading(false);
		}
	};

	useEffect(() => {
		if (page > 1) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, memoizedFilter]);

	const fetchMoreData = () => {
		if (!isFetchingPage && hasMore) {
			setPage((prev) => prev + 1);
		}
	};

	const reset = () => {
		setPage(1);
		setItems([]);
		fetchData(1);
	};
	const clear = () => {
		setPage(1);
		setItems([]);
	};

	useEffect(() => {
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedFilter]);

	useEffect(() => {
		handleMutateCallback && handleMutateCallback(fetchData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleMutateCallback]);

	if (!isFetchingPage && items.length === 0) return EmptyComponent;

	return (
		<InfiniteScroll
			style={style}
			dataLength={items.length}
			next={fetchMoreData}
			hasMore={hasMore}
			scrollThreshold={0.4}
			loader={<></>}
			{...(scrollableTarget && {
				scrollableTarget: scrollableTarget
			})}
			className={className}>
			{items?.map((item, index) => children(item, index, fetchData, reset, clear))}
			{isFetchingPage &&
				CusomLoader &&
				Array.from(new Array(PAGE_SIZE))?.map((_, index) => <CusomLoader key={index} />)}
		</InfiniteScroll>
	);
};

export default InfiniteScrollComponent;
